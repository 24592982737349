<template>
  <div class="questionnaire">
        <div class="formulaire container" id="thematiques">
          <form v-on:submit.prevent="submitForm">
                <div v-if="profil.provenance=='akto_rse'" class="pt-5 text-justify intro-desc-akto">
                  <div class="text-left mb-4 px-4 pt-5 question-akto">
                    <p class="fw-bold">
                      <span class="badge rounded-pill badge-primary">Question A</span>
                      <br>Parmi les catégories suivantes, quelle est celle qui définit le mieux votre entreprise ?
                    </p>
                    <div class="py-1 px-4 text-left">
                      <div class="pb-1">
                        <b-row class="mb-3">
                          <b-col sm="6">
                            <b-form-select id="input-categorie" v-model="profil.categorie" :options="categorieOptions" required style="width: 75%; font-size:80%"></b-form-select>  
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <p class="fw-bold">
                      <span class="badge rounded-pill badge-primary">Question B</span>
                      <br>Quelle est la taille de votre entreprise ?
                    </p>
                    <div class="py-1 px-4 text-left">
                      <div class="pb-1">
                        <b-row class="mb-3">
                          <b-col sm="6">
                            <b-form-select id="input-taille" v-model="profil.effectif" :options="tailleOptions" required style="width: 75% ; font-size:80%"></b-form-select>  
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="profil.provenance=='MAIF_association' || profil.provenance=='MAIF_entreprise'" class="pt-5 text-justify intro-desc-akto">
                  <div class="text-left mb-4 px-4 pt-5 question-akto">
                    <p class="fw-bold">
                      <span class="badge rounded-pill badge-primary">Question A</span>
                      <br>Quelle est la taille de votre organisation ?
                    </p>
                    <div class="py-1 px-4 text-left">
                      <div class="pb-1">
                        <b-row class="mb-3">
                          <b-col sm="6">
                            <b-form-select id="input-taille" v-model="profil.effectif" :options="tailleOptionsMAIF" required style="width: 75% ; font-size:80%"></b-form-select>  
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </div>

          <div v-for="(thematique,i) in questionnaire" v-bind:key="thematique.id" class="pt-3">
            <div class="thematique mx-5 pt-5" :id="parseInt(thematique.id)+1">
              <div class="thematique-img" :style="thematique.style">
                <img :src="publicPath+thematique.imagePath" :alt="thematique.alt" class="img-fluid"
                    height="72px"
                    width="72px"/>
              </div>
              <div class="text-center mt-2">
                <span>ENGAGEMENT #{{ parseInt(thematique.id)+1 }}</span><h2 :id="'item-'+i">{{ thematique.nom }}</h2>
              </div>
            </div>
            <div class="mb-5 pb-2 text-left">
                <div v-for="(question,j) in thematique.questions" v-bind:key="question.id" class="text-left question mb-2" >
                  <div class="text-left py-2 mb-4 px-1">
                        <p>
                          <span class="badge rounded-pill badge-green" :style="thematique.style">Question {{ parseInt(question.id)+1 }} -  ({{j+1}}/{{thematique.nbQuestions}})</span> <br>{{ question.nom }}
                        </p>
                        <div class="py-1 px-3 text-left">
                          <div v-for="(reponse,i) in question.reponses" v-bind:key="reponse.id" class="pb-1">
                            <input type="radio" class="btn-check" autocomplete="off"
                                  v-bind:name="'option'+ question.id"
                                  v-bind:id="'radio'+question.id+reponse.id"
                                  v-bind:value="reponse.value"
                                  v-model="question.choisi"
                                  :disabled="formDisabled"
                                  required
                                  > <!-- required -->
                            <label class="btn btn-outline-primary btn-sm container-fluid shadow-sm rounded text-left" 
                                  v-bind:for="'radio'+question.id+reponse.id">
                                  <span class="badge badge-library" :style="thematique.style">{{i | library(i) }}</span>{{ reponse.text }}
                            </label>
                        </div>
                      </div>

                      <div v-if="profil.provenance=='akto_rse'">
                          <p class="text-center fw-normal my-0" ><small>Quel niveau de priorité attribuez-vous à cet enjeu ?</small></p>
                        <div class="rating">

    <b-form-group class="text-center radio-danger" v-slot="{ ariaDescribedby }">
      <b-row class="text-center radio-danger starLabel my-2 fw-bold">
        <b-form-radio-group
          v-model="question.pertinence"
          :options="radioOptions"
          :aria-describedby="ariaDescribedby"
          v-bind:name="'optionStar'+ question.id"
          plain
          button-variant="danger"
        ></b-form-radio-group>
      </b-row>
    </b-form-group>

                        </div> 
                    </div>


                  </div>
                </div>
            </div>
            </div>
            <div class="text-center mb-5 pb-5" v-if="!formDisabled">
                <button type="submit" class="btn btn-lg" aria-label="Valider les réponses" :disabled="validerOff"><small>Valider</small> <b-spinner small v-if="spin"></b-spinner></button>
            </div>
        </form>

          <transition name="fade">
            <div class="mb-3 pb-3 resultats" v-if="formDisabled">
            <!-- <div class="mb-3 pb-3 resultats"> -->
              <div class="bg-white graphique shadow-sm rounded pt-3">
                <div id="chart">
                    <apexchart 
                    ref="radarchart"
                    type="radar" 
                    height="350" 
                    :options="opt.chartOptions" 
                    :series="opt.series" 
                    id="lala"></apexchart>
                </div>
              </div>
              <form v-on:submit.prevent="submitChart" class="mt-5">
                <div v-if="profil.provenance=='akto_rse'" class="mt-2 mx-4 text-justify intro-desc-akto">
                 <!-- <div class="text-left mb-4 px-4 question-akto">
                    <p class="fw-bold">
                      <span class="badge rounded-pill badge-primary">Question C</span>
                      <br>Toutes les réponses seront soigneusement analysées et contribueront de façon essentielle à l’élaboration et la mise en œuvre d’un référentiel partagé à toute la filière. Par ailleurs, une synthèse des principaux enseignements sera réalisée. Si vous souhaitez obtenir cette synthèse, merci d’indiquer votre adresse mail :
                    </p>
                  </div>
                  <div class="text-left mb-4 px-4 question-akto">
                    <p class="fw-bold">
                      <span class="badge rounded-pill badge-primary">Question D</span>
                      <br>Accepteriez-vous de participer à des travaux avec d’autres acteurs de la filière à partir de septembre 2022 ? Si oui, merci de préciser vos coordonnées afin de pouvoir vous contacter avant les congés d’été.
                    </p>
                      <b-col sm="8">
                        <b-form-input id="input-tel" placeholder="Coordonnées" v-model="profil.coordonnees" type="text"></b-form-input>
                      </b-col>
                  </div>
                  -->
                  <div class="text-left mb-4 px-4 question-akto">
                    <p class="fw-bold">
                      <span class="badge rounded-pill badge-primary">Question C</span>
                      <br>Si vous souhaitez être tenu au courant des suites de ce projet, vous pouvez nous communiquer vos coordonnées ci-dessous : 
                    </p>
                      <b-col sm="8" class="my-2">
                        <b-form-input id="input-mail" placeholder="Adresse mail" v-model="profil.adresse" type="email"></b-form-input>
                      </b-col>
                      <b-col sm="8" class="my-2">
                        <b-form-input id="input-nom" placeholder="Nom" v-model="profil.nom" type="text"></b-form-input>
                      </b-col>
                      <b-col sm="8" class="my-2">
                        <b-form-input id="input-prenom" placeholder="Prénom" v-model="profil.prenom" type="text"></b-form-input>
                      </b-col>
                      <b-col sm="8" class="my-2">
                        <b-form-input id="input-organisation" placeholder="Organisation" v-model="profil.organisation" type="text"></b-form-input>
                      </b-col>
                  </div>
                  <b-row class="my-2 mx-2">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" name="flexCheckResult" id="flexCheckResult1" required>
                      <label class="form-check-label" for="flexCheckResult">
                        J'accepte que les informations saisies soient exploitées dans le cadre de l'analyse et la diffusion des enseignements de cette enquête.
                      </label>
                    </div>
                  </b-row>
                  <b-row class="my-2 mx-2">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" name="flexCheckTravaux" id="flexCheckTravaux" v-model="profil.travaux">
                      <label class="form-check-label" for="flexCheckTravaux">
                        Je souhaite participer à des travaux en septembre pour coconstruire le référentiel et partager mon expérience.
                      </label>
                    </div>
                  </b-row>
                  <b-row class="my-2 mx-2 mb-3">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" name="flexCheckSynthese" id="flexCheckSynthese" v-model="profil.synthese">
                      <label class="form-check-label" for="flexCheckSynthese">
                        Je souhaite recevoir la synthèse des enseignements de ce questionnaire et le référentiel de la branche.
                      </label>
                    </div>
                  </b-row>
                  <div class="text-left mb-4 px-4 question-akto">
                    <p class="fw-bold">
                      <span class="badge rounded-pill badge-primary">Question D</span>
                      <br>Recommanderiez-vous d’administrer ce questionnaire à une autre personne de votre entreprise / réseau professionnel ? Si oui, merci de préciser une adresse mail :
                    </p>
                      <b-col sm="8">
                        <b-form-input id="input-mail2" placeholder="Adresse mail autre personne" v-model="profil.courriel_autre" type="email"></b-form-input>
                      </b-col>
                  </div>
                  <div class="text-left mb-4 px-4 question-akto">
                    <p class="fw-bold">
                      <span class="badge rounded-pill badge-primary">Question E</span>
                      <br>Avez-vous d’autres remarques ou commentaires à nous transmettre ?
                    </p>
                    <b-col sm="10">
                            <b-form-textarea
                              v-model="profil.remarques"
                              id="input-remarques"
                              placeholder="Remarques"
                              rows="2"
                              max-rows="8"
                            ></b-form-textarea>
                     </b-col>
                  </div>
                </div>
                <div v-else class="mx-4">
                <p><strong>Obtenir la synthèse des résultats par mail :</strong></p>
                    <b-row class="my-1">
                      <b-col sm="3">
                        <label for="input-nom">Nom</label>
                      </b-col>
                      <b-col sm="6">
                        <b-form-input id="input-nom" placeholder="Nom" v-model="profil.nom" type="text" required></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="my-1">
                      <b-col sm="3">
                        <label for="input-prenom">Prénom</label>
                      </b-col>
                      <b-col sm="6">
                        <b-form-input id="input-prenom" placeholder="Prénom" v-model="profil.prenom" type="text" required></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="my-1" v-if="profil.provenance=='louvre_hotels'">
                      <b-col sm="3">
                        <label for="input-organisation">Hôtel</label>
                      </b-col>
                      <b-col sm="6">
                        <b-form-input id="input-organisation" placeholder="Nom de votre hôtel" v-model="profil.organisation" type="text" required></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="my-1" v-else>
                      <b-col sm="3">
                        <label for="input-organisation">Organisation</label>
                      </b-col>
                      <b-col sm="6">
                        <b-form-input id="input-organisation" placeholder="Nom de votre organisation" v-model="profil.organisation" type="text" required></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="my-1" v-if="profil.provenance!='MAIF_association' && profil.provenance!='MAIF_entreprise' && profil.provenance!='louvre_hotels'">
                      <b-col sm="3">
                        <label for="input-type">Type d'organisation</label>
                      </b-col>
                      <b-col sm="6">
                        <b-form-select id="input-type" v-model="profil.type" :options="typeOptions" required></b-form-select>  
                      </b-col>
                    </b-row>
                    <b-row class="my-1" v-if="profil.provenance=='louvre_hotels'">
                      <b-col sm="3">
                        <label for="input-effectif">Effectif de votre hôtel</label>
                      </b-col>
                      <b-col sm="6">
                        <b-form-select id="input-effectif" v-model="profil.effectif" :options="effectifOptions" required></b-form-select>  
                      </b-col>
                    </b-row>
                    <b-row class="my-1" v-else-if="profil.provenance!='MAIF_association' && profil.provenance!='MAIF_entreprise'">
                      <b-col sm="3">
                        <label for="input-effectif">Effectif de votre organisation</label>
                      </b-col>
                      <b-col sm="6">
                        <b-form-select id="input-effectif" v-model="profil.effectif" :options="effectifOptions" required></b-form-select>  
                      </b-col>
                    </b-row>
                    <b-row class="my-1">
                      <b-col sm="3">
                        <label for="input-mail">E-mail</label>
                      </b-col>
                      <b-col sm="6">
                        <b-form-input id="input-mail" placeholder="Adresse mail" v-model="profil.adresse" type="email" required></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="my-1">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="flexCheckResult" id="flexCheckResult1" required>
                        <label class="form-check-label" for="flexCheckResult" v-if="this.profil.provenance=='MAIF' || this.profil.provenance=='MAIF_association' || this.profil.provenance=='MAIF_entreprise'">
                          J'accepte que la MAIF et Goodwill-management collectent mon adresse électronique pour me transmettre les résultats de mon auto-diagnostic RSE.
                        </label>
                        <label class="form-check-label" for="flexCheckResult" v-else-if="this.profil.provenance=='LUCIE'">
                          J'accepte que LUCIE et Goodwill-management collectent mon adresse électronique pour me transmettre les résultats de mon auto-diagnostic RSE.
                        </label>
                        <label class="form-check-label" for="flexCheckResult" v-else-if="this.profil.provenance=='bakertilly'">
                          J'accepte que Baker Tilly et Goodwill-management collectent mon adresse électronique pour me transmettre les résultats de mon auto-diagnostic RSE.
                        </label>
                        <label class="form-check-label" for="flexCheckResult" v-else>
                          J'accepte que Goodwill-management collecte mon adresse électronique pour me transmettre les résultats de mon auto-diagnostic RSE.
                        </label>
                      </div>
                    </b-row>
                    <b-row class="my-1" v-if="this.profil.provenance!='louvre_hotels'">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" name="flexCheckNewsletter" id="flexCheckNewsletter1" v-model="profil.newsletter">
                        <label class="form-check-label" for="flexCheckNewsletter" v-if="this.profil.provenance=='MAIF' || this.profil.provenance=='MAIF_association' || this.profil.provenance=='MAIF_entreprise'">
                          Je souhaite recevoir les informations de la MAIF.
                        </label>
                        <label class="form-check-label" for="flexCheckNewsletter" v-else-if="this.profil.provenance=='LUCIE'">
                          Je souhaite recevoir les informations de LUCIE (newsletter et événements).
                        </label>
                        <label class="form-check-label" for="flexCheckNewsletter" v-else-if="this.profil.provenance=='bakertilly'">
                          Je souhaite recevoir les informations de BakerTilly (newsletter et événements).
                        </label>
                        <label class="form-check-label" for="flexCheckNewsletter" v-else>
                          Je souhaite recevoir les informations de Goodwill-management (newsletter et événements).
                        </label>
                      </div>
                    </b-row>  
                  </div>                  
                  <button type="submit" class="mt-3 mx-3 btn btn-primary" aria-label="Envoyer les informations" v-show="!repondu">Envoyer</button>
                  <div v-if="profil.provenance=='akto_rse'">
                    <div class="alert alert-success fade show my-3" role="alert" v-show="envoiSucces">
                      <strong>Réponse enregistrée !</strong> Nous vous remercions.
                    </div>
                    <div class="alert alert-danger fade show my-3" role="alert" v-show="envoiError">
                      <strong>Erreur.</strong> Veuillez réessayer.
                    </div>
                  </div>
                  <div v-else>
                    <div class="alert alert-success fade show my-3" role="alert" v-show="envoiSucces">
                      <strong>Synthèse envoyée !</strong> Vous devriez la recevoir d'ici quelques minutes. Pensez à consulter vos spams.
                    </div>
                    <div class="alert alert-danger fade show my-3" role="alert" v-show="envoiError">
                      <strong>Erreur.</strong> Notre système est momentanément indisponible.
                    </div>
                  </div>
              </form>
          </div> 
        </transition>
    </div>
  </div>
</template>
<script src="https://unpkg.com/apexcharts"></script>
<script src="https://unpkg.com/vue-apexcharts"></script>
<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios';
import VueApexCharts from 'vue-apexcharts'
import { v4 as uuidv4 } from 'uuid';
import qGoodwill from '../questionnaires/qGoodwill.js'
import qLHG from '../questionnaires/qLHG.js'
import qAKTO from '../questionnaires/qAKTO.js'
import qMAIFEntr from '../questionnaires/qMAIFEntr.js'
import qMAIFAsso from '../questionnaires/qMAIFAsso.js'


export default {
  name: 'Questionnaire',
  props: {
    questionnaire: Array
  },
  data(){
        return {
            publicPath: process.env.BASE_URL,
            show:false,
            formDisabled:false,  
            spin: false,
            validerOff: false,
            envoiSucces: false,
            envoiError: false,
            repondu: false,
            typeOptions: [
              { value: null, text: "Sélectionnez votre type d'organisation" },
              { value: 'Entreprise', text: 'Entreprise' },
              { value: 'Collectivité', text: 'Collectivité' },
              { value: 'Association', text: 'Association' },
              { value: 'Administration', text: 'Administration, établissement public' }
            ],
            effectifOptions: [
              { value: null, text: 'Sélectionnez votre effectif' },
              { value: 'Moins de 10 personnes', text: 'Moins de 10 personnes' },
              { value: 'de 10 à 49 personnes', text: 'de 10 à 49 personnes' },
              { value: 'de 50 à 249 personnes', text: 'de 50 à 249 personnes' },
              { value: 'de 250 à 4999 personnes', text: 'de 250 à 4999 personnes' },
              { value: 'Plus de 5000 personnes', text: 'Plus de 5000 personnes' }
            ],
            categorieOptions: [
              { value: null, text: 'Sélectionnez une catégorie' },
              { value: 'Location entretien d’articles textiles et autres services', text: 'Location entretien d’articles textiles et autres services' },
              { value: 'Blanchisserie-teinturerie de gros', text: 'Blanchisserie-teinturerie de gros ' },
              { value: 'Blanchisserie-teinturerie de détail', text: 'Blanchisserie-teinturerie de détail' }
            ],
            tailleOptions: [
              { value: null, text: 'Sélectionnez votre taille' },
              { value: 'Entre 1 et 9 employés ', text: 'Entre 1 et 9 employés ' },
              { value: 'Entre 10 et 49 employés ', text: 'Entre 10 et 49 employés ' },
              { value: 'Entre 50 et 249 employés ', text: 'Entre 50 et 249 employés ' },
              { value: 'Entre 250 et 5 000 employés', text: 'Entre 250 et 5 000 employés' },
              { value: 'Plus de 5 000 employés', text: 'Plus de 5 000 employés' }
            ],
            tailleOptionsMAIF: [
              { value: null, text: 'Sélectionnez votre taille' },
              { value: 'Entre 1 et 9 collaborateurs ', text: 'Entre 1 et 9 collaborateurs ' },
              { value: 'Entre 10 et 49 collaborateurs ', text: 'Entre 10 et 49 collaborateurs ' },
              { value: 'Entre 50 et 249 collaborateurs ', text: 'Entre 50 et 249 collaborateurs ' },
              { value: 'Entre 250 et 5 000 collaborateurs', text: 'Entre 250 et 5 000 collaborateurs' },
              { value: 'Plus de 5 000 collaborateurs', text: 'Plus de 5 000 collaborateurs' }
            ],
            profil: {
              uuid: '',
              nom: '',
              prenom: '',
              organisation: '',
              adresse: '',
              provenance: '',
              effectif: null,
              type: null,
              newsletter: '',
              synthese: '',
              travaux: '',
              categorie: null,
              remarques: ''
            },
            radioOptions: [
          { text: 'Non prioritaire', value: '1' },
          { text: 'Peu prioritaire', value: '2' },
          { text: 'Prioritaire', value: '3' },
          { text: ' Très prioritaire', value: '4' }
        ],
            result: '',
            components: {
                apexchart: VueApexCharts
            },


        opt: {
            series: [{
                name: 'Score',
                data: [13, 9, 11, 16, 8, 10, 13],
            }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'radar',
              toolbar: {
                show: false,
              }
            },
            dataLabels: {
              enabled: true,
                background: {
                    enabled: true,
                    borderRadius:2,
                }
            },
            plotOptions: {
              radar: {
                size: 140,
                polygons: {
                  strokeColors: '#e9e9e9',
                  fill: {
                    colors: ['#f8f8f8', '#fff']
                  }
                }
              }
            },
            /*title: {
              text: 'Radar with Polygon Fill'
            },*/
            colors: ['#3d405b'],
            markers: {
              size: 4,
              colors: ['#fff'],
              strokeColor: '#333366',
              strokeWidth: 2,
            },
            tooltip: {
              y: {
                formatter: function(val) {
                  return val
                }
              }
            },
            xaxis: {
              categories: ['Gouvernance', ['Droits des', 'personnes'], ['Relations et', 'conditions', 'de travail'], 'Environnement', "Relations d'affaires", ['Intérêts des', 'consommateurs'], ['Développement', 'local']],
              labels: {
                      show: true,
                      rotate: -45,
                      rotateAlways: false,
                      hideOverlappingLabels: true,
                      showDuplicates: false,
                      trim: false,
                      minHeight: undefined,
                      maxHeight: 120,
                      style: {
                          colors: ['#3d405b','#3d405b','#3d405b','#3d405b','#3d405b','#3d405b','#3d405b'],
                          fontSize: '10px',
                          fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
                          fontWeight: 600,
                          cssClass: 'apexcharts-xaxis-label',
                      }
              },
            },
            yaxis: {
                min: 0,
                max: 20,
              tickAmount: 4,
              labels: {
                formatter: function(val, i) {
                  if (i % 2 === 0) {
                    return val
                  } else {
                    return ''
                  }
                }
              }
            }
          }
        },

        }
    },
    methods:{
        getImage(path) {
          return require(path + '')
        },
        submitForm(){
            this.spin = true
            this.validerOff = true
            console.log(this.questionnaire)
            // https://diagrse.goodwill-management.com/form
            // https://diagrse.greenshift.eu/form
            // http://localhost:3080/form
            axios.post('https://diagrse.goodwill-management.com/form', { profil: this.profil, questionnaire: this.questionnaire, queryId: this.$route.query.id })
                .then((res) => {
                    //Perform Success Action
                    this.formDisabled = true
                    this.spin = false
                    this.result = res.data
                    this.opt.series = [{
                        data: res.data
                    }]
                    const el = this.$el.getElementsByClassName('resultats')[0];

                    if (el) {
                      // Use el.scrollIntoView() to instantly scroll to the element
                      el.scrollIntoView({behavior: 'smooth'});
                    }
                })
                .catch((error) => {
                    // error.response.status Check status code
                    console.log(error)
                }).finally(() => {
                    //Perform action in always

                });
        },
        submitChart() {
            // console.log('submitChart...')
                
                this.$refs.radarchart.chart.dataURI().then(uri => {

                    //console.log(uri)
                    // https://diagrse.goodwill-management.com/email
                    // https://diagrse.greenshift.eu/email
                    // http://localhost:3080/email
                    axios.post('https://diagrse.goodwill-management.com/email', {uri: uri, profil: this.profil, questionnaire: this.questionnaire })
                        .then((res) => {
                            //Perform Success Action
                            // console.log(res)
                            this.envoiSucces = true
                            //window.open('https://diagrse.goodwill-management.com/getrse', '_blank');
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            // console.log(error)
                            this.envoiError = true
                        }).finally(() => {
                            //Perform action in always
                            this.repondu = true
                        });       
                });
       }

    },
    created() {
      this.profil.provenance = this.$route.query.id
      // console.log('provenance : ' + this.profil.provenance)
      this.profil.uuid = uuidv4()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400');

/*@font-face {
  font-family: "Montserrat-Regular";
  src:  local(Montserrat-Regular),
        url('../font/Montserrat/Montserrat-Regular.ttf') format("font-truetype");
}*/

.logos img {
  max-width:8em;
}

.intro h1 {
    font-size:150%;
}
.intro h2 {
  font-size:110%;
  font-weight: 400;
}

.intro-desc {
  font-weight: 300;
}

.intro-desc-akto {
  text-align: justify;
}

.intro-thematique {
    max-width: 8em;
    font-size:0.8em;
}

.img-rounded {
    width:6em;
    height:6em;
    margin:auto;
    /*border: solid white;*/
    border-radius: 50px;
    background-color: #3d405b!important;
    box-shadow: 1px 3px black;
}

.intro-thematique img {
    max-width:6em;
    filter: brightness(0.95);

}

.intro-thematique a {
  color: #333333!important;
  text-decoration: none!important;
}

.intro-button {
  background-color: #e07a5f!important;
}

.btn-lg {
  font-weight: 900;
}

.btn-outline-primary, .btn-primary {
  border-color: #3d405b!important;
}

.btn-primary {
  border-width: 0.1em;
  border-radius:10em;
  background-color: #3d405b!important;
}

.btn-primary:hover {
    background-color: white!important;
    border-color: #3d405b!important;
    color: #3d405b!important;
}

.btn-outline-primary {
  background-color: white!important;
  color:#3d405b!important;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-check:focus + .btn-outline-primary {
  background-color: #ededf5!important;
  border-color: #3d405b!important;
  color: #3d405b!important;
}

.btn-outline-primary:active, .btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary {
  background-color: #3d405b!important;
  border-color: #3d405b!important;
  color:white!important;
  outline: none !important;
  box-shadow: none!important;
}

.btn-sm {
  font-size:0.85rem;
  text-align: left;
  padding: 0.3rem 0.3rem 0.2rem 0.3rem;
}

h2 {
    color:#3d405b!important;
    vertical-align: middle;
    font-weight: 600;
}

.thematique-img {
    width:4em;
    height:4em;
    margin:auto;
    /*border: solid white;*/
    border-radius: 50px;
    box-shadow: 1px 3px black!important;;
}

.thematique-img img {
    max-width:4em;
}

.thematique span {
  margin: auto;
}

.question p, .question-akto p {
    font-size:90%;
}

.badge {
    margin-right: 0.5em;
}

.badge-green {
  color:white!important;
  background-color: #81b29a!important;
}

.badge-primary {
  color:white!important;
  background-color: #3d405b!important;
}

.badge-library {
  border: 0.1rem solid white!important;
}

.text-dark {
  color: #3d405b!important;
}

.bg-light {
  background-color: white!important;
  border: 1px solid #3d405b!important;
}

.question {
  max-width: 45em;
  margin: auto;
}

.question p {
  font-weight: 700;
}

.question option:hover {
    background-color:#eeeeee!important;
}

.question option:active:focus {
    background-color:#3d405b!important;
    color:white!important;
}

.graphique {
  max-width: 30em;
  margin:auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.question .btn {
  text-align: left;
}

.rating .form-control {
  background-color: transparent!important;
}

.starLabel {
  font-size:75%;
}

.starLabel .form-check-input:checked, .starLabel .form-check-input:after {
    background-color: #3d405b!important;
    border-color: #3d405b!important;
}

.form-check-input:checked, .form-check-input:checked:after {
    background-color: #3d405b!important;
    border-color: #3d405b!important;
}

.commentaires .badge {
 background-color: #3d405b!important;
}

.commentaires textarea {
  font-size:80%;
}

/*.commentaires textarea {
  border-color: #3d405b!important;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}*/

.rating .form-control:focus, .commentaires .btn-check:focus + .btn, .commentaires .btn:focus {
    border-color: transparent!important;;
    outline: 0;
    box-shadow: 0 0 0 0.25rem transparent!important;
}

.form-check-input:checked {
  background-color: #3d405b;
  border-color: #3d405b;
}
</style>
