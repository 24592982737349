<template>
    <div id="app">
      <div class="sidebar shadow-sm">
        <div class="justify-content-center text-center mx-3 mt-4 title-sidebar">
          <div>Evaluation RSE</div><br>
        </div>
          <small class="mx-3 subtitle-sidebar" v-if="showQuestionnaire"><strong>ENGAGEMENTS</strong></small>
          <div v-for="(thematique,i) in bubbles" v-bind:key="thematique.id" class="item-sidebar shadow-sm" v-show="showQuestionnaire">
            <small><a :href="'#'+(parseInt(thematique.id)+1)" :style="thematique.style">
              <span class="img-sidebar px-1">
                  <img :src="publicPath+thematique.imagePath" :href="i" :alt="thematique.alt"
                      height="26px"
                      width="26px"/>
              </span>
              <span class="mt-2 nom-thematique">{{ thematique.nom }}</span>
              </a>
              
            </small>
          </div>
        <div class="container text-center logo-goodwill">
          <div class="row logos d-flex justify-content-center">
            <img v-if="this.profil.provenance=='LUCIE'" src="./assets/logo-communaute.png" alt='logo-communaute-lucie' class="img-fluid">
            <img v-if="this.profil.provenance=='bakertilly'" src="./assets/logo-bakertilly.png" alt='logo-bakertilly-strego' class="img-fluid">
            <img v-if="this.profil.provenance=='louvre_hotels'" src="./assets/logo-louvre-hotels.png" alt='logo-louvre-hotels' class="img-fluid">
            <img src="./assets/logo-goodwill-gris.png" alt='logo-goodwill-management' class="img-fluid">
          </div>
        </div>
      </div>
    <div class="content">
      <Accueil :bubbles=bubbles @showQuestionnaire="showQuestionnaire=true" :questionnaire="questionnaire" @getCounter="setCounter"></Accueil>
      <footer class="text-muted pt-4 pb-2">
          <div class="container">
            <small>
            <p class="float-right">
              <a href="#">Revenir en haut</a>
            </p>
            <p>Copyright © 2022 Goodwill-management - Un site de Goodwill-management - Tous droits réservés</p>
            </small>
          </div>
        </footer>
    </div>
</div>
</template>
<script src="https://unpkg.com/apexcharts"></script>
<script src="https://unpkg.com/vue-apexcharts"></script>
<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import HelloWorld from './components/HelloWorld.vue';
import Accueil from './components/Accueil.vue';
import Questionnaire from './components/Questionnaire.vue';
import qGoodwill from './questionnaires/qGoodwill.js'
import qLHG from './questionnaires/qLHG.js'
import qAKTO from './questionnaires/qAKTO.js'
import qMAIFEntr from './questionnaires/qMAIFEntr.js'
import qMAIFAsso from './questionnaires/qMAIFAsso.js'

export default {
  components: { HelloWorld, Accueil, Questionnaire },
    /*name: 'Evaluation RSE',*/
    metaInfo: {
      title: 'Evaluation RSE',
      htmlAttrs: {
        lang: 'fr'
      },
       meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'Réalisez en quelques clics un premier diagnostic RSE de votre organisation basé sur 7 questions centrales.' }
      ]
    },
    /* urlProd: 'https://diagrse.goodwill-management.com',
    urlPreprod: 'https://diagrse.greenshift.eu/'
    localhost: 'http://localhost:3080', */
    data(){
        return {
            publicPath: process.env.BASE_URL,
            show:false,
            formDisabled:false,
            showQuestionnaire: false,
            spin: false,
            validerOff: false,
            envoiSucces: false,
            envoiError: false,
            questionnaire: null,
            questionnaireGen: qGoodwill,
            questionnaireHotel: qLHG,
            questionnaireAkto: qAKTO,
            questionnaireMAIFEntr: qMAIFEntr,
            questionnaireMAIFAsso: qMAIFAsso,
            bubbles:[
            {
                id: 0,
                nom: 'Gouvernance',
                titre: 'Mettre en place une gouvernance responsable',
                imagePath: 'gouvernance.svg',
                alt: 'logo-gouvernance',
                style: {
                  'border-left-color':'#457b9d!important',
                  'border-left-style': 'solid',
                  'border-left-width': '0.2em'
                }
            },
            {
                id: 1,
                nom: "Droits des personnes",
                titre: 'Respecter les droits des personnes',
                imagePath: 'human-rights.svg', 
                alt: 'logo-droits-des-personnes',
                style: {
                  'border-left-color':'#ba5941!important',
                  'border-left-style': 'solid',
                  'border-left-width': '0.2em'
                }
            },
            {
                id: 2,
                nom: 'Relations et conditions de travail',
                titre: 'Développer des relations et conditions de travail responsables',
                imagePath: 'relation-travail.svg',
                alt: 'logo-relations-et-conditions-de-travail',
                style: {
                  'border-left-color':'#5B5EA4!important',
                  'border-left-style': 'solid',
                  'border-left-width': '0.2em'
                }
            },
            {
                id: 3,
                nom: 'Environnement',
                titre: "Préserver l'environnement",
                imagePath: 'environnement.svg', 
                alt: 'logo-environnement',
                style: {
                  'border-left-color':'#51806a!important',
                  'border-left-style': 'solid',
                  'border-left-width': '0.2em'
                }
            },
            {
                id: 4,
                nom: "Relations d'affaires",
                titre: "Développer l'éthique dans les relations d'affaires",
                imagePath: 'relations-fournisseurs.svg',
                alt: 'logo-relations-affaires',
                style: {
                  'border-left-color':'#3e1f47!important',
                  'border-left-style': 'solid',
                  'border-left-width': '0.2em'
                }
            },
            {
                id: 5,
                nom: 'Intérêts des consommateurs',
                titre: 'Respecter les intérêts des consommateurs',
                imagePath: 'consommation.svg',
                alt: 'logo-interets-consommateurs',
                style: {
                  'border-left-color':'#cc4647!important',
                  'border-left-style': 'solid',
                  'border-left-width': '0.2em'
                }
            },
            {
                id: 6,
                nom: 'Développement local',
                titre: "Viser le développement local et l'intérêt général",
                alt: 'logo-developpement-local',
                imagePath: 'societal.svg', 
                style: {
                   'border-left-color':'#197278!important',
                  'border-left-style': 'solid',
                  'border-left-width': '0.2em'
                }
            }],
            profil: {
              uuid: '',
              nom: '',
              prenom: '',
              organisation: '',
              adresse: '',
              provenance: '',
              effectif: null,
              type: null,
              newsletter: '',
              categorie: null,
              coordonnees: '',
              remarques: ''
            },
            result: ''
        }
    },
    created() {
      this.profil.provenance = this.$route.query.id
      // console.log('provenance : ' + this.profil.provenance)
      this.profil.uuid = uuidv4()
      if(this.profil.provenance == 'akto_rse') {
        this.questionnaire = this.questionnaireAkto
      } else if(this.profil.provenance == 'MAIF_association') {
        this.questionnaire = this.questionnaireMAIFAsso
        this.type = "association"
      } else if(this.profil.provenance == 'MAIF_entreprise') {
        this.questionnaire = this.questionnaireMAIFEntr
        this.type = "entreprise"
      } else if(this.profil.provenance == 'louvre_hotels') {
        this.questionnaire = this.questionnaireHotel
      } else {
        this.questionnaire = this.questionnaireGen
      }
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa!important;
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: #f8f9fa!important;
  font-family: 'Poppins', Helvetica, 'Arial';
  scroll-behavior: smooth;
}

.container-fluid {
    padding-right: var(--bs-gutter-x,.0rem);
    padding-left: var(--bs-gutter-x,.0rem);
}

.fullscreen {
  min-height: 100vh;
}

.sidebar img {
  max-width:8em;
}

/* FOOTER */

.float-right {
    float: right !important;
}

footer a {
  color:#6c757d;
} 

footer a:hover {
  color:#3d405b;
} 

footer p {
    margin-bottom: .25rem;
}

/* ENDFOOTER */


 /* The side navigation menu */
.sidebar {
  margin: 0;
  padding: 0;
  width: 300px;
  background-color: white;
  position: fixed;
  height: 100%;
  overflow: auto;
}

/* Sidebar links */
.sidebar a {
  display: block;
  color: #666;
  margin: 0.5em 0em 0.5em 0em;
  padding: 0.5em 0em 0.5em 0em;
  text-decoration: none;
  /*border-left-color: white;
  border-left-style: solid;
  border-left-width: 0.2em;*/
      border-color:none!important;
    border-left-color: none!important;
    border-left-width: 0px!important;
}

/* Active/current link */
/*.sidebar a.active, .sidebar a:active, .sidebar a:focus, .sidebar a.focus {
  background-color: #333;
  color: white;
}*/

/* Links on mouse-over */
.sidebar a:hover:not(.active) {
  /*background-color: #ededf5;*/ /* #ededf5 */
  color: black;
  border-left-color: #3d405b;
  border-left-style: solid;
  border-left-width: 0.2em;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
  margin-left: 300px;
  padding: 1px 0px;
  height: 100vh;
}

.subtitle-sidebar {
  font-size: 80%;
  color: #3d405b;
}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: fixed;
    top:0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 289;
  }
  .sidebar a {
    float: left;
    border-color:none!important;
    border-left-color: none!important;
    border-left-width: 0px!important;
  }
  div.content {
      margin-left: 0px;
      padding: 0px 0px;
  }

  .nom-thematique {
    display: none;
  }

  .logo-goodwill {
    display:none;
  }
  .sidebar img {
    max-width: 4em;
  }

  .title-sidebar {
    display: none;
  }


}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
    border: none;
  }

  .nom-thematique {
    display: none;
  }
}

.img-sidebar {
  filter: brightness(0.4);
}

.sidebar a {
  font-size: 90%;
}

.btn-primary {
  border: none;
}

.btn-lg {
  font-size: 90%;
  font-weight: 600;
  border-width: 0.1em;
  border-radius:10em;
  padding-top:0.7em;
  background-color: #3d405b!important;
  border-color: transparent!important;
  box-shadow: 1px 3px black;
  color:white;
}

.btn-lg:hover {
    background-color: black!important;
    border-color: black!important;
    color: white!important;
    box-shadow: 1px 3px black
}

</style>