const quest = [
    {
        id: 0,
        nom: 'Gouvernance',
        titre: 'Mettre en place une gouvernance responsable',
        imagePath: 'gouvernance.svg',
        alt: 'logo-gouvernance',
        style: {
          backgroundColor: '#3165cc!important'
        },
        questions: [
            { id: 0, 
              nom: "La stratégie RSE de l'entreprise existe-t-elle et est-elle intégrée à sa stratégie globale ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "L’entreprise n’a pas encore mis en place d’actions RSE  " },
                { id: '4', value: '8', text: "Des actions RSE sont mises en place mais aucune stratégie n’a été formalisée" },
                { id: '5', value: '12', text: "La stratégie est formalisée et connue de tous les salariés (s’il y en a)" },
                { id: '6', value: '17', text: "La RSE est pleinement intégrée à la stratégie de l’entreprise, elle est connue des clients et fournisseurs et est portée par les dirigeants" }
              ] },
            { id: 1, 
              nom: "Avez-vous identifié les différents risques auxquels l’entreprise fait face et sont-ils suivis ?",  
              choisi: null,
              pertinence:null, 
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, l’entreprise n’a pas encore identifié ses risques " },
                { id: '4', value: '8', text: "Les risques sont connus et pilotés sur le plan économique, un plan d’actions est mis en place" },
                { id: '5', value: '12', text: "L’entreprise est consciente des risques sociaux (réputation, marque employeur, devoir de vigilance…) et environnementaux (raréfactions des ressources naturelles, énergies fossiles, changement climatique…) mais ils ne sont pas formalisés" },
                { id: '6', value: '17', text: "Les risques sont parfaitement pilotés sur le plan social et environnemental, un plan d’actions est mis en place" }
              ] }
          ]
    },
    {
        id: 1,
        nom: "Droits des personnes",
        titre: 'Respecter les droits des personnes',
        imagePath: 'human-rights.svg', 
        alt: 'logo-droits-des-personnes',
        style: {
          backgroundColor: '#cc5522!important'
        }, 
        questions: [
            { id: 2, 
              nom: "Des moyens sont-ils mis en œuvre pour éviter toute forme de harcèlement ou discrimination ? ",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "Les collaborateurs sont sensibilisés aux situations de harcèlement ou de discrimination" },
                { id: '5', value: '12', text: "L’entreprise a prévu et formalisé des sanctions" },
                { id: '6', value: '17', text: "L’entreprise est très mature sur le sujet, a mis en place un système d’alerte et lutte activement contre toute forme de harcèlement ou de discrimination" }
              ] },
            { id: 3, 
              nom: "Des moyens sont-ils mis en œuvre pour lutter contre le travail illégal (cumul irrégulier d’emplois, travail dissimulé, emploi irrégulier de travailleurs étrangers, rémunération en deçà des minimaux légaux etc.) ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L’entreprise s’acquitte de ses obligations réglementaires (déclaration auprès de l’URSSAF, vérification identités des salariés etc.)" },
                { id: '5', value: '12', text: "L’entreprise est exemplaire en interne (prévention, actualisation des données, dispositif d’alerte etc.)" },
                { id: '6', value: '17', text: "L’entreprise porte une vigilance particulière sur le travail illégal sur l’ensemble de la chaine de valeur, notamment en contrôlant ses fournisseurs" }
              ] },
            { id: 4, 
              nom: "La protection des données de l'ensemble des parties prenantes est-elle assurée ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L’entreprise a mis en place un registre des données et des consignes de traitement" },
                { id: '5', value: '12', text: "L’entreprise s’assure de la bonne application des règles de conservation des données" },
                { id: '6', value: '17', text: "L’entreprise mène des contrôles réguliers pour vérifier la sécurité de son système d’information et des données qui y sont conservées" }
              ] }
          ]
    },
    {
        id: 2,
        nom: 'Relations et conditions de travail',
        titre: 'Développer des relations et conditions de travail responsables',
        imagePath: 'relation-travail.svg',
        alt: 'logo-relations-et-conditions-de-travail',
        style: {
          backgroundColor: '#5B5EA4!important'
        }, 
        questions: [
            { id: 5, 
              nom: "L’entreprise minimise-t-elle le recours à l’emploi temporaire (CDD, intérim, saisonniers...) ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, le sujet n’est pas pris en main par l’entreprise" },
                { id: '4', value: '8', text: "Elle y travaille mais il y a encore beaucoup de CDD et d’intérimaires" },
                { id: '5', value: '12', text: "L’entreprise a fortement réduit le nombre d’emplois temporaires, et y recourt uniquement lorsque nécessaire (remplacement, hausse temporaire de l’activité)" },
                { id: '6', value: '17', text: "Les emplois sont pérennes, les emplois temporaires disposent des mêmes avantages que les non-temporaires, et l’entreprise s’engage en faveur de l’insertion des personnes éloignées de l’emploi" }
              ] },
            { id: 6, 
              nom: "La qualité de vie au travail est-elle assurée pour l’ensemble des salariés (pénibilité, horaires, conditions de travail, etc.) ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "De premières actions sont mises en place pour réduire la pénibilité mais l’entreprise peut progresser" },
                { id: '5', value: '12', text: "De nombreuses actions sont engagées sur l’amélioration des conditions de travail et le confort des locaux. Les premiers résultats sont visibles" },
                { id: '6', value: '17', text: "L’entreprise est très mature sur le sujet, en particulier sur la souplesse des horaires et l’écoute des salariés. Les résultats sont reconnus de tous" }
              ] },
            { id: 7, 
              nom: "L’entreprise travaille-t-elle pour protéger la santé et sécurité de ses collaborateurs ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L’entreprise a construit son DUER (document unique d’évaluation des risques) et mis en œuvre quelques actions" },
                { id: '5', value: '12', text: "De nombreuses actions sont engagées et les accidents du travail sont en baisse" },
                { id: '6', value: '17', text: "L’entreprise est très mature sur le sujet, elle pilote ses actions et a de très bons résultats " }
              ] },
            { id: 8, 
              nom: "L’ensemble des salariés sont-ils formés ? ",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "L’entreprise propose rarement des formations (moins d’une fois tous les 2 ans en moyenne par salarié), en dehors de ses obligations légales et réglementaires" },
                { id: '4', value: '8', text: "L’entreprise propose une formation tous les 2 ans en moyenne par salarié" },
                { id: '5', value: '12', text: "L’entreprises propose une formation par an en moyenne par salarié, pour répondre aux besoins actuels de l’entreprise" },
                { id: '6', value: '17', text: "Tous les salariés suivent des formations régulières selon un plan de développement des compétences qui anticipe les évolutions futures de l’entreprise" }
              ] },
            { id: 9, 
              nom: "Le système de rémunération est-il équitable et transparent ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Le système est opaque ou non formalisé" },
                { id: '4', value: '8', text: "La grille de salaire existe, elle est équitable (égalité femme-homme…) mais non partagée" },
                { id: '5', value: '12', text: "La grille de salaire existe, elle est équitable (égalité femme-homme…) et est partagée" },
                { id: '6', value: '17', text: "La grille de salaire existe, le processus de décision est transparent, l’écart salarial est effectivement minimisé" }
              ] }
        ]
    },
    {
        id: 3,
        nom: 'Environnement',
        titre: "Préserver l'environnement",
        imagePath: 'environnement.svg', 
        alt: 'logo-environnement',
        style: {
          backgroundColor: '#51806a!important'
        }, 
        questions: [
            { id: 10, 
              nom: "L’entreprise réduit-elle sa production de déchets et augmente-t-elle leur taux de valorisation ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "L’entreprise ne trie pas et ne réduit pas ses déchets" },
                { id: '4', value: '8', text: "L’entreprise trie et recycle une partie de ses déchets" },
                { id: '5', value: '12', text: "L’entreprise pilote et réduit sa production de déchets et recycle lorsque c’est possible" },
                { id: '6', value: '17', text: "L’entreprise a une politique zéro-déchet non valorisé et contribue à l’économie circulaire" }
              ] },
            { id: 11, 
              nom: "L’entreprise cherche-t-elle à diminuer ses consommations de ressources (énergie, eau etc.) ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L’entreprise a mis en place quelques actions d’économie d’électricité, d’eau..." },
                { id: '5', value: '12', text: "L’entreprise suit ses consommations d’eau et d’énergie et œuvre pour leur réduction" },
                { id: '6', value: '17', text: "L’entreprise s’est fixé des objectifs ambitieux de réduction de consommation d’énergie et d’eau et met en place des projets innovants pour les atteindre" }
              ] },
            { id: 12, 
              nom: "L’entreprise cherche-t-elle à diminuer ses émissions de gaz à effet de serre ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L’entreprise a mis en œuvre quelques actions pour diminuer ses émissions de gaz à effet de serre" },
                { id: '5', value: '12', text: "L’entreprise a fait un bilan d’émissions de gaz à effet de serre et a construit un plan d’actions pour faire baisser ses émissions." },
                { id: '6', value: '17', text: "L’entreprise a mis en œuvre un plan ambitieux de réduction de ses émissions et contribue à la neutralité carbone par sa trajectoire" }
              ] },
            { id: 13, 
              nom: "L’entreprise a-t-elle structuré une démarche chiffrée de protection de l’environnement ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "Quelques indicateurs sont suivis (consommation d’eau, déchets...)" },
                { id: '5', value: '12', text: "L’entreprise a mis en place un tableau de bord complet permettant de suivre ses indicateurs environnementaux" },
                { id: '6', value: '17', text: "L’entreprise s’est fixée des objectifs ambitieux, suit régulièrement ses indicateurs, et met en œuvre un plan d’actions pour avoir un impact positif sur l’environnement (désartificialisation des sols, protection de la biodiversité...)" }
              ] },
            { id: 14, 
              nom: "L’entreprise développe-t-elle une offre de services éco-responsable ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "Quelques actions sont mises en œuvre mais l’entreprise ne communique pas dessus auprès de ses clients" },
                { id: '5', value: '12', text: "Une partie des services de l’entreprise est éco-responsable" },
                { id: '6', value: '17', text: "L’intégralité des services proposés par l’entreprise rentre dans une démarche d’éco-responsabilité. L’entreprise sait évaluer l’impact de sa démarche environnementale." }
              ] }
        ]
    },
    {
        id: 4,
        nom: "Relations d'affaires",
        titre: "Développer l'éthique dans les relations d'affaires",
        imagePath: 'relations-fournisseurs.svg',
        alt: 'logo-relations-affaires',
        style: {
          backgroundColor: '#3e1f47!important'
        },  
        questions: [
            { id: 15, 
              nom: "L’entreprise respecte-t-elle les délais de paiement ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non elle les dépasse régulièrement" },
                { id: '4', value: '8', text: "Les délais réglementaires sont respectés" },
                { id: '5', value: '12', text: "Des actions sont engagées pour aller au-delà de la réglementation" },
                { id: '6', value: '17', text: "L’entreprise est exemplaire et réduit le plus possible les délais de paiement" }
              ] },
            { id: 16, 
              nom: "L’entreprise lutte-t-elle contre toute forme de corruption et concurrence déloyale ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "De premières actions sont mises en œuvre mais l’entreprise peut progresser" },
                { id: '5', value: '12', text: "De nombreuses actions sont engagées et les premiers résultats sont visibles" },
                { id: '6', value: '17', text: "L’entreprise est très mature sur le sujet, elle pilote ses actions et leur efficacité" }
              ] },
            { id: 17, 
              nom: "Des critères sociaux et environnementaux sont-ils pris en compte dans le choix des fournisseurs ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "Oui mais de manière informelle" },
                { id: '5', value: '12', text: "Les critères RSE sont pris en compte mais influencent peu la prise de décision" },
                { id: '6', value: '17', text: "Les critères RSE sont pris en compte dans l’ensemble des consultations et détiennent une influence importante" }
              ] },
        ]
    },
    {
        id: 5,
        nom: 'Intérêts des consommateurs',
        titre: 'Respecter les intérêts des consommateurs',
        imagePath: 'consommation.svg',
        alt: 'logo-interets-consommateurs',
        style: {
          backgroundColor: '#cc4647!important'
        }, 
        questions: [
            { id: 18, 
              nom: "Les contrats, les engagements vis-à-vis des clients et les informations sur les services sont-elles transparentes ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "De premières actions sont mises en œuvre mais l’entreprise peut progresser" },
                { id: '5', value: '12', text: "De nombreuses actions sont engagées et les premiers résultats sont visibles" },
                { id: '6', value: '17', text: "L’entreprise est reconnue pour la transparence de ses pratiques commerciales et sur ses produits et services (aucun litige client, forte satisfaction sur la qualité de l’information communiquée...)" }
              ] },
            { id: 19, 
              nom: "L’entreprise garantit-elle la sécurité de ses produits et services pour protéger la santé de ses clients ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L’entreprise a mis en œuvre quelques actions pour limiter les risques pour les consommateurs" },
                { id: '5', value: '12', text: "L’entreprise a mis en place une politique de santé et sécurité de ses produits et services en veillant notamment à réduire l’usage de substances nocives" },
                { id: '6', value: '17', text: "L’entreprise a mis en place un système de management certifié ou labellisé de la santé et sécurité des produits pour le consommateur" }
              ] },
              { id: 20, 
              nom: "L’entreprise accompagne-t-elle ses clients vers des modes de consommation plus responsables ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L’entreprise communique ponctuellement sur la consommation responsable" },
                { id: '5', value: '12', text: "L’entreprise communique sur la valeur ajoutée de ses produits sur les enjeux sociaux et environnementaux" },
                { id: '6', value: '17', text: "L’entreprise promeut de nouveaux modes de consommation (location, économie de fonctionnalité, limitation des soldes…) et incite ses clients à moins consommer" }
              ] },
              { id: 21, 
              nom: "L’entreprise mesure-t-elle la satisfaction de ses clients ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "Oui mais de manière ponctuelle" },
                { id: '5', value: '12', text: "Oui de manière systématique" },
                { id: '6', value: '17', text: "Oui et un plan d’actions est mis en place pour améliorer l’offre" }
              ] }
        ]
    },
    {
        id: 6, 
        nom: 'Développement local',
        titre: "Viser le développement local et l'intérêt général",
        alt: 'logo-developpement-local',
        imagePath: 'societal.svg', 
        style: {
          backgroundColor: '#197278!important'
        }, 
        questions: [
            { id: 22, 
              nom: "L’entreprise œuvre-t-elle pour le développement local ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "Oui, l’entreprise privilégie le recrutement local" },
                { id: '5', value: '12', text: "L’entreprise contribue au territoire à travers le recrutement local et en favorisant les achats et investissements locaux" },
                { id: '6', value: '17', text: "L’entreprise est très engagée sur le sujet et mesure ses impacts sur le territoire" }
              ] },
            { id: 23, 
              nom: "L’entreprise contribue-t-elle à des initiatives d’intérêt général ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "Oui elle fait des dons ponctuels à des associations" },
                { id: '5', value: '12', text: "L’entreprise a un engagement pérenne auprès d’associations de son territoire" },
                { id: '6', value: '17', text: "L’entreprise est engagée sur le long-terme auprès d’associations et implique ses salariés dans sa politique de mécénat" }
              ] }
        ]
    }]
export default quest;