import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import { BootstrapVue } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import AwesomeVueStarRating from 'awesome-vue-star-rating'
import routes from './routes';
import VueMeta from 'vue-meta';
import VueAnalytics from 'vue-analytics';

// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faPhone } from "@fortawesome/free-solid-svg-icons";

// library.add(faPhone);

// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// Vue.component("font-awesome-icon", FontAwesomeIcon)

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({mode: 'history', routes});

Vue.use(VueAnalytics, {
  id: 'UA-69431406-1',
  router
})

Vue.use(BootstrapVue);
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('AwesomeVueStarRating', AwesomeVueStarRating)

Vue.filter('library', function (value) {
	switch(value) {
    case 0: return 'A'
    case 1: return 'B'
    case 2: return 'C'
    case 3: return 'D'
    default: return 'O'
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
