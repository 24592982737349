<template>
  <div class="accueil">
          <div class="intro container-fluid text-center" id="top">
          <div class="fullscreen mx-2">
              <h1 v-if="this.profil.provenance=='akto_rse'" class="container">Participez à une étude sur la RSE dans la branche entretien et location textile !</h1>
              <h1 v-else class="container">Évaluation de votre maturité RSE</h1>
              <h2 v-if="this.profil.provenance=='akto_rse'">Évaluez votre démarche et aidez-nous à construire la démarche RSE de la branche</h2>
              <div v-if="this.profil.provenance=='akto_rse'" class="mx-3 intro-desc px-1 py-2 mt-1 text-justify">
                <p class="text-center fw-bold">Répondre à ce questionnaire prendra entre 5 et 10 minutes.</p>
                <p class="text-justify intro-desc-akto">
                  Dans le cadre du projet d’Engagement de Développement de l’Emploi et des Compétences (EDEC) au sein de la filière Grands Événements Culturels et Sportifs, co-financé par le Ministère du Travail, du Plein Emploi, et de l’Insertion, <span class="fw-bold">la branche Entretien-Location Textile travaille à la mise en œuvre d’une politique de Responsabilité Sociétale (RSE).</span>
                </p>
                <p class="text-justify intro-desc-akto">
                  À destination des entreprises de la branche, ce questionnaire a pour objectif de faire un <span class="fw-bold">état des lieux des pratiques RSE</span> du secteur et d’<span class="fw-bold">identifier les enjeux prioritaires</span>, secondaires ou non applicables à la création d’un référentiel.
                </p>
                <p class="text-justify intro-desc-akto">
                  Ce questionnaire comporte 24 questions portant chacune sur un enjeu lié à la RSE, visant à évaluer : 
                <ol>
                  <li><span class="fw-bold">Vos pratiques</span>, en évaluant votre niveau de maturité selon une échelle de A (début de prise en compte) à D (exemplarité). Il n’y a donc pas de bonne ou de mauvaise réponse à donner. </li>
                  <li><span class="fw-bold">La priorité</span> de cet enjeu pour votre entreprise, selon une échelle de 1 à 4. Non prioritaire ne signifie pas qu’un enjeu n’est pas important à vos yeux, mais simplement qu’une bonne ou une mauvaise gestion de cet enjeu aura peu d'impact sur votre activité. Si vous estimez ne pas être concerné par un sujet ou une question, vous pouvez indiquer qu’il s’agit d’un enjeu « non prioritaire ».</li>
                </ol>

                </p>
                <p class="text-justify intro-desc-akto">
                  Nous vous remercions pour votre participation à ce questionnaire, qui aidera la branche à construire une <span class="fw-bold">démarche RSE pertinente</span>, porteuse de sens et créatrice de valeur pour toutes ses parties prenantes.
                </p>
                <p class="text-justify intro-desc-akto fw-bold">
                  À la fin du questionnaire, vous accéderez à un diagnostic synthétique de votre maturité RSE selon les 7 thématiques d’engagements de ce questionnaire. 
                </p>
              </div>
              <div v-else-if="this.profil.provenance=='louvre_hotels'">
                <p class="mx-3 intro-desc px-1 py-2 mt-1">En répondant à quelques questions simples, nous vous proposons de réaliser un premier <strong>diagnostic RSE</strong> de votre hôtel.
                  <br><br>
                  Pour un diagnostic efficace, vous devez répondre à l'ensemble des questions. Répondez le plus spontanément possible :
                  dès la réponse à la dernière question vous découvrirez le profil de votre hôtel sur <strong>7 questions centrales</strong>
                </p>
              </div>
              <div v-else>
                <p class="mx-3 intro-desc px-1 py-2 mt-1">En répondant à quelques questions simples, nous vous proposons de réaliser un premier <strong>diagnostic RSE</strong> de votre organisation.
                  <br><br>
                  Pour un diagnostic efficace, vous devez répondre à l'ensemble des questions. Répondez le plus spontanément possible :
                  dès la réponse à la dernière question vous découvrirez le profil de votre entité sur <strong>7 questions centrales</strong>
                </p>
              </div>
            <div class="panel-bubble">
              <div class="d-flex flex-row flex-wrap text-center justify-content-center justify-content-sm-center py-2 my-3">
                  <div v-for="(thematique,i) in bubbles" v-bind:key="thematique.id" class="mx-2 my-3 py-1 intro-thematique d-inline">
                      <a>
                          <div class="img-rounded">
                              <img :src="publicPath+thematique.imagePath" :href="i" :alt="thematique.alt"
                                  height="64px"
                                  width="64px"/>
                          </div>
                          <div class="mt-2">{{ thematique.nom }}</div>
                      </a>
                  </div>
              </div>
            </div>
            <a class="btn mt-1 btn-lg" aria-label="Commencer" href="#thematiques" v-on:click="showQuest()"> <small>Commencer</small> </a>
        </div>
        </div>
            <div v-if="showQuestionnaire">
              <Questionnaire :questionnaire="questionnaire" @getCounter="setCounter"></Questionnaire>
            </div>
    </div>
</template>
<script src="https://unpkg.com/apexcharts"></script>
<script src="https://unpkg.com/vue-apexcharts"></script>
<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Questionnaire from '../components/Questionnaire.vue';

export default {
  name: 'Accueil',
  components: { Questionnaire },
  props: {
    bubbles: Array,
    questionnaire: Array
  },
  data(){
        return {
            publicPath: process.env.BASE_URL,
            show:false,
            showQuestionnaire:false,
            formDisabled:false,
            spin: false,
            validerOff: false,
            envoiSucces: false,
            envoiError: false,
            profil: {
              uuid: '',
              nom: '',
              prenom: '',
              organisation: '',
              adresse: '',
              provenance: '',
              effectif: null,
              type: null,
              newsletter: '',
              categorie: null,
              coordonnees: '',
              remarques: ''
            },
        }
    },
    methods:{
        getImage(path) {
          return require(path + '')
        },
        showQuest() {
          this.showQuestionnaire = true
          this.$emit('showQuestionnaire')
        }
    },
    created() {
      this.profil.provenance = this.$route.query.id
      // console.log('provenance : ' + this.profil.provenance)
      this.profil.uuid = uuidv4()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400');

/*@font-face {
  font-family: "Montserrat-Regular";
  src:  local(Montserrat-Regular),
        url('../font/Montserrat/Montserrat-Regular.ttf') format("font-truetype");
}*/
/*
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa!important;
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: #f8f9fa!important;
  font-family: 'Poppins', Helvetica, 'Arial';
  scroll-behavior: smooth;
} */

.container-fluid {
    padding-right: var(--bs-gutter-x,.0rem);
    padding-left: var(--bs-gutter-x,.0rem);
}

/*.fullscreen {
  min-height: 100vh;
}*/

/* FOOTER */

footer {
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.float-right {
    float: right !important;
}

footer a {
  color:#6c757d;
} 

footer a:hover {
  color:#3d405b;
} 

footer p {
    margin-bottom: .25rem;
}

/* ENDFOOTER */

/*.logos {
  max-height:3em;
}*/

.logos img {
  max-width:8em;
}

.intro {
  padding-top: 4.2em;
  padding-bottom: 1em;
}

.intro h1 {
    font-size:150%;
}
.intro h2 {
  font-size:110%;
  font-weight: 400;
}

.intro-desc {
  font-weight: 300;
  font-size: 90%;
}

.intro-desc-akto {
  text-align: justify;
}

.intro-thematique {
    max-width: 8em;
    font-size:0.8em;
}

.img-rounded {
    width:5em;
    height:5em;
    margin:auto;
    /*border: solid white;*/
    border-radius: 50px;
    background-color: #3d405b!important;
    box-shadow: 1px 3px black!important;
    perspective: 40em;
}

.intro-thematique img {
    max-width:6em;
    filter: brightness(0.95);

}

.intro-thematique a {
  color: #333333!important;
  text-decoration: none!important;
}

.intro-button {
  background-color: #e07a5f!important;
}

.btn-outline-primary {
  border-color: #3d405b!important;
}

.btn-outline-primary {
  background-color: white!important;
  color:#3d405b!important;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-check:focus + .btn-outline-primary {
  background-color: #ededf5!important;
  border-color: #3d405b!important;
  color: #3d405b!important;
}

.btn-outline-primary:active, .btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary {
  background-color: #3d405b!important;
  border-color: #3d405b!important;
  color:white!important;
  outline: none !important;
  box-shadow: none!important;
}

.btn-sm {
  font-size:0.75rem;
  text-align: left;
  padding: 0.3rem 0.3rem 0.2rem 0.3rem;
}

h2 {
    color:#3d405b!important;
    vertical-align: middle;
    font-weight: 600;
}

.thematique-img {
    width:4em;
    height:4em;
    margin:auto;
    /*border: solid white;*/
    border-radius: 50px;
}

.thematique-img img {
    max-width:4em;
}

.thematique span {
  margin: auto;
}

.question p, .question-akto p {
    font-size:90%;
}

.badge {
    margin-right: 0.5em;
}

.badge-green {
  color:white!important;
  background-color: #81b29a!important;
}

.badge-primary {
  color:white!important;
  background-color: #3d405b!important;
}

.badge-library {
  border: 0.1rem solid white!important;
}

.text-dark {
  color: #3d405b!important;
}

.bg-light {
  background-color: white!important;
  border: 1px solid #3d405b!important;
}

.question {
  max-width: 45em;
  margin: auto;
}

.question p {
  font-weight: 700;
}

.question option:hover {
    background-color:#eeeeee!important;
}

.question option:active:focus {
    background-color:#3d405b!important;
    color:white!important;
}

.graphique {
  max-width: 30em;
  margin:auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.question .btn {
  text-align: left;
}

.rating .form-control {
  background-color: transparent!important;
}

.starLabel {
  font-size:75%;
}

.commentaires .badge {
 background-color: #3d405b!important;
}

.commentaires textarea {
  font-size:80%;
}

/*.commentaires textarea {
  border-color: #3d405b!important;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}*/

.rating .form-control:focus, .commentaires .btn-check:focus + .btn, .commentaires .btn:focus {
    border-color: transparent!important;;
    outline: 0;
    box-shadow: 0 0 0 0.25rem transparent!important;
}

.form-check-input:checked {
  background-color: #3d405b;
  border-color: #3d405b;
}
</style>