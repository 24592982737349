const quest = [
    {
        id: 0,
        nom: 'Gouvernance',
        titre: 'Mettre en place une gouvernance responsable',
        imagePath: 'gouvernance.svg',
        alt: 'logo-gouvernance',
        style: {
          backgroundColor: '#3165cc!important'
        },
        questions: [
            { id: 0, 
              nom: "La stratégie de responsabilité sociétale ou de développement durable existe-t-elle et est-elle intégrée à votre projet d'association ? ",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, l'association n'a pas encore mis en place d'actions RSO" },
                { id: '4', value: '8', text: "Des actions RSO sont mises en place mais la RSO n'est pas intégrée au projet de l'association" },
                { id: '5', value: '12', text: "La stratégie RSO est intégrée au projet associatif et est connue de tous" },
                { id: '6', value: '17', text: "Nos engagements RSO sont pleinement intégrés dans le projet associatif, ils sont connus des bénéficiaires, fournisseurs ou partenaires et sont portée par les responsables et les administrateurs" }
              ] },
            { id: 1, 
              nom: "Avez-vous identifié les différents risques auxquels l'association fait face et sont-ils suivis ?",  
              choisi: null,
              pertinence:null, 
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, l'association n'a pas encore identifié ses risques" },
                { id: '4', value: '8', text: "Les risques sont connus et pilotés sur le plan économique, un plan d'action est mis en place" },
                { id: '5', value: '12', text: "L'association est consciente des risques sociaux (réputation, devoir de vigilance…) et environnementaux (raréfactions des ressources naturelles, énergies fossiles, changement climatique…) mais ils ne sont pas formalisés." },
                { id: '6', value: '17', text: "Les risques sont parfaitement pilotés sur le plan social et environnemental, un plan d'action est mis en place" }
              ] }
          ]
    },
    {
        id: 1,
        nom: "Droits des personnes",
        titre: 'Respecter les droits des personnes',
        imagePath: 'human-rights.svg', 
        alt: 'logo-droits-des-personnes',
        style: {
          backgroundColor: '#cc5522!important'
        }, 
        questions: [
            { id: 2, 
              nom: "Des moyens sont-ils mis en œuvre pour éviter toute forme de harcèlement ou discrimination ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "Les collaborateurs et bénévoles sont sensibilisés aux situations de harcèlement ou de discrimination" },
                { id: '5', value: '12', text: "L'association a prévu et formalisé des sanctions" },
                { id: '6', value: '17', text: "L'association est très mature sur le sujet, a mis en place un système d'alerte et lutte activement contre toute forme de harcèlement ou de discrimination" }
              ] },
            { id: 3, 
              nom: "Des moyens sont-ils mis en oeuvre pour lutter contre le travail illégal et respecter les règles propres au bénévolat ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L'association s'acquitte de ses obligations réglementaires et respecte les règles propres au bénévolat" },
                { id: '5', value: '12', text: "L'association est exemplaire en interne" },
                { id: '6', value: '17', text: "L'association est exemplaire et porte une vigilance particulière sur le travail illégal sur l'ensemble de la chaîne de valeur, notamment en contrôlant ses fournisseurs" }
              ] },
            { id: 4, 
              nom: "La protection des données de l'ensemble des parties prenantes est-elle assurée ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L'association a mis en place un registre des données et des consignes de traitement" },
                { id: '5', value: '12', text: "L'association s'assure de la bonne application des règles de conservation des données" },
                { id: '6', value: '17', text: "L'association mène des contrôles réguliers pour vérifier la sécurité de son système d'information et des données qui y sont conservées" }
              ] }
          ]
    },
    {
        id: 2,
        nom: 'Relations et conditions de travail',
        titre: 'Développer des relations et conditions de travail responsables',
        imagePath: 'relation-travail.svg',
        alt: 'logo-relations-et-conditions-de-travail',
        style: {
          backgroundColor: '#5B5EA4!important'
        }, 
        questions: [
            { id: 5, 
              nom: "La qualité de vie au travail est-elle assurée pour l’ensemble des collaborateurs et des bénévoles (pénébilité, horaires, conditions de travail, etc.) ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "De premières actions sont mises en place pour réduire la pénibilité mais l'association peut progresser" },
                { id: '5', value: '12', text: "De nombreuses actions sont engagées sur l'amélioration des conditions de travail et de confort des locaux. Les premiers résultats sont visibles." },
                { id: '6', value: '17', text: "L'association est très mature sur le sujet, en particulier sur la souplesse des horaires et l'écoute des employés et bénévoles. Les résultats sont reconnus de tous." }
              ] },
            { id: 6, 
              nom: "Le télétravail est-il possible ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non" },
                { id: '4', value: '8', text: "Oui pour une partie des salariés" },
                { id: '5', value: '12', text: "Oui pour tous mais au maximum 1 jour par semaine" },
                { id: '6', value: '17', text: "Oui pour 100% des postes télétravaillables et ce plusieurs jours par semaine" }
              ] },
            { id: 7, 
              nom: "L’association travaille-t-elle pour protéger la santé et la sécurité de ses collaborateurs et des bénévoles ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L'association a construit son DUER (document unique d'évaluation des risques) et mis en oeuvre quelques actions" },
                { id: '5', value: '12', text: "De nombreuses actions sont engagées et les accidents du travail sont en baisse" },
                { id: '6', value: '17', text: "L’association est très mature sur le sujet, elle pilote ses actions pour tous (collaborateurs, bénévoles ou usagers) et a de très bons résultats" }
              ] },
            { id: 8, 
              nom: "L'ensemble des salariés sont-ils formés ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "L'association propose rarement des formations (moins d'une fois tous les 2 ans en moyenne par salarié), en dehors de ses obligations légales et réglementaires" },
                { id: '4', value: '8', text: "L'association propose une formation tous les 2 ans en moyenne par salarié" },
                { id: '5', value: '12', text: "L'association propose une formation par an en moyenne par salarié, pour répondre aux besoins actuels de l'association" },
                { id: '6', value: '17', text: "Tous les salariés suivent des formations régulières selon un plan de développement ds compétences qui anticipe les évolutions futures de l'association" }
              ] },
            { id: 9, 
              nom: "Le système de rémunération est-il équitable et transparent ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Le système est opaque ou non formalisé" },
                { id: '4', value: '8', text: "La grille de salaire existe, elle est équitable (égalité femme-homme...) mais non partagée" },
                { id: '5', value: '12', text: "La grille de salaire existe, elle est équitable (égalité femme-homme…) et est partagée" },
                { id: '6', value: '17', text: "La grille de salaire existe, le processus de décision est transparent et l'écart salarial est effectivement minimisé" }
              ] }
        ]
    },
    {
        id: 3,
        nom: 'Environnement',
        titre: "Préserver l'environnement",
        imagePath: 'environnement.svg', 
        alt: 'logo-environnement',
        style: {
          backgroundColor: '#51806a!important'
        }, 
        questions: [
            { id: 10, 
              nom: "L'association réduit-elle sa production de déchets et augmente-t-elle leur taux de valorisation ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "L'association ne trie pas et ne réduit pas ses déchets" },
                { id: '4', value: '8', text: "L'association trie et recycle une partie de ses déchets" },
                { id: '5', value: '12', text: "L'association pilote et réduit sa production de déchets et recycle lorsque c'est possible" },
                { id: '6', value: '17', text: "L'association a une politique zéro-déchet non valorisé et contribue à l'économie circulaire" }
              ] },
            { id: 11, 
              nom: "L'association cherche-t-elle à diminier ses consommations de ressources (énergies, eau etc.) ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L'association a mis en place quelques actions d'économie d'électricité, d'eau..." },
                { id: '5', value: '12', text: "L'association suit ses consommations d'eau er d'énergie et oeuvre pour leur réduction" },
                { id: '6', value: '17', text: "L'association s'est fixée des objectifs ambitieux de réduction de consommation d'énergie et d'eau et met en place des projets innovants pour les atteindre" }
              ] },
            { id: 12, 
              nom: "L'association cherche-t-elle à diminuer ses émissions de gaz à effet de serre ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L'association a mis en oeuvre quelques actions pour diminuer des émissions de gaz à effet de serre" },
                { id: '5', value: '12', text: "L'association a fait un bilan d'émissions de gaz à effet de serre et a construit un plan d'action  pour faire baisser ses émissions" },
                { id: '6', value: '17', text: "L'association a mis en oeuvre un plan ambitieux de réduction de ses émissions et contribue à la neutralité carbone par sa trajectoire" }
              ] },
            { id: 13, 
              nom: "L'association a-t-elle structuré une démarche chiffrée de protection de l'environnement ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "Quelques indicateurs sont suivis (consommation d'eau, déchets...)" },
                { id: '5', value: '12', text: "L'association a mis en place un tableau de bord complet permettant de suivre ses indicateurs environnementaux" },
                { id: '6', value: '17', text: "L'association s'est fixée des objectifs ambitiaux, suit régulièrement ses indicateurs et met en œuvre un plan d'action pour avoir un impact positif sur l'environnement (incluant la désartificialisation des sols, la protection de la biodiversité...)" }
              ] },
            { id: 14, 
              nom: "L'association réduit-elle l'impact du numérique ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L'association sensibilise ses salariés au sujet" },
                { id: '5', value: '12', text: "L'association allonge la durée de vie de ses équipements informatiques" },
                { id: '6', value: '17', text: "L'association a une démarche avancée sur le sujet (durée de vie et recyclage du matériel, sites web éco-conçus…)" }
              ] }
        ]
    },
    {
        id: 4,
        nom: "Relations d'affaires",
        titre: "Développer l'éthique dans les relations d'affaires",
        imagePath: 'relations-fournisseurs.svg',
        alt: 'logo-relations-affaires',
        style: {
          backgroundColor: '#3e1f47!important'
        },  
        questions: [
            { id: 15, 
              nom: "L'association respecte-t-elle les délais de paiement ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, elle les dépasse régulièrement" },
                { id: '4', value: '8', text: "Les délais réglementaires sont respectés" },
                { id: '5', value: '12', text: "Des actions sont engagées pour aller au-delà de la réglementation" },
                { id: '6', value: '17', text: "L'association est exemplaire et réduit le plus possible les délais de paiement" }
              ] },
            { id: 16, 
              nom: "L'association lutte-t-elle contre toute forme de corruption et conflit d'intérêt ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "De premières actions sont mises en œuvre mais l'association peut progresser" },
                { id: '5', value: '12', text: "De nombreuses actions sont engagées et les premiers résultats sont visibles" },
                { id: '6', value: '17', text: "L'association est très mature sur le sujet, elle pilote ses actions et leur efficacité." }
              ] },
            { id: 17, 
              nom: "Des critères sociaux et environnementaux (achats locaux, bio, éco-conçus, auprès de l'ESS, secteurs protégés-adaptés …) sont-ils pris en compte dans le choix des fournisseurs ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "Oui mais de manière informelle" },
                { id: '5', value: '12', text: "Les critères RSO sont pris en compte mais influencent peu la prise de décision" },
                { id: '6', value: '17', text: "Les critères RSO sont pris en compte dans l'ensemble des consultations et ont une influence importante" }
              ] },
        ]
    },
    {
        id: 5,
        nom: 'Intérêts des consommateurs',
        titre: 'Respecter les intérêts des consommateurs',
        imagePath: 'consommation.svg',
        alt: 'logo-interets-consommateurs',
        style: {
          backgroundColor: '#cc4647!important'
        }, 
        questions: [
            { id: 18, 
              nom: "Les contrats, les engagements vis-à-vis des bénéficiaires et les informations sur les produits ou services sont-ils transparentes ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "De premières actions sont mises en œuvre mais l'association peut progresser" },
                { id: '5', value: '12', text: "De nombreuses actions sont engagées et les premiers résultats sont visibles" },
                { id: '6', value: '17', text: "L'association est reconnue pour la transparence de ses pratiques sur ses produits et services (aucun litige, forte satisfaction sur la qualité de l'information communiquée...)" }
              ] },
            { id: 19, 
              nom: "L'association garantit-elle la sécurité de ses produits et services pour protéger la santé de ses bénéficiaires",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n’est mise en œuvre sur le sujetNon, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L'association a mis en oeuvre quelques actions pour limiter les risques pour les bénéficiaires" },
                { id: '5', value: '12', text: "L'association a mis en place une politique de santé et sécurité de ses produits et services en veillant notamment à réduire l'usage de substances nocives" },
                { id: '6', value: '17', text: "L'association a mis en place un système de management certifié ou labellisé de la santé et sécurité des produits pour les bénéficiaires" }
              ] },
              { id: 20, 
              nom: "L'association accompagne-t-elle ses bénéficiaires vers des modes de consommation plus responsables ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "L'association communique ponctuellement sur la consommation responsable" },
                { id: '5', value: '12', text: "L'association communique sur la valeur ajoutée de ses produits ou services sur les enjeux sociaux et environnementaux" },
                { id: '6', value: '17', text: "L'association promeut de nouveaux modes de consommation (location, économie de la fonctionnalité…) et incite ses bénéficiaires à moins consommer" }
              ] },
              { id: 21, 
              nom: "L'association mesure-t-elle la satisfaction de ses bénéficiaires ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "Oui mais de manière ponctuelle" },
                { id: '5', value: '12', text: "Oui de manière systématique" },
                { id: '6', value: '17', text: "Oui et un plan d'action est mis en place pour améliorer les prestations" }
              ] }
        ]
    },
    {
        id: 6, 
        nom: 'Développement local',
        titre: "Viser le développement local et l'intérêt général",
        alt: 'logo-developpement-local',
        imagePath: 'societal.svg', 
        style: {
          backgroundColor: '#197278!important'
        }, 
        questions: [
            { id: 22, 
              nom: "L'association œuvre-t-elle pour le développement local ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "Oui, l'association privilégie le recrutement local" },
                { id: '5', value: '12', text: "L'association contribue au territoire à travers le recrutement local et en favorisant les achats et investissements locaux" },
                { id: '6', value: '17', text: "L'association a mis en place des synergies avec des acteurs locaux et mesure ses impacts sur le territoire" }
              ] },
            { id: 23, 
              nom: "Votre association contribue-t-elle à l’intérêt général ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, l'association ne contribue pas à l’intérêt général " },
                { id: '4', value: '8', text: "Oui, par son activité l'association contribue à l’intérêt général mais n’est pas reconnue comme telle" },
                { id: '5', value: '12', text: "Oui, l'association est reconnue d’intérêt général" },
                { id: '6', value: '17', text: "Oui, notre association est reconnue d’intérêt général et est engagée sur le long-terme auprès d’autres asssociation et du tissu économique local pour avoir plus d’impact" }
              ] }
        ]
    }]
export default quest;