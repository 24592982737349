const quest = [{
        id: 0,
        nom: 'Gouvernance',
        titre: 'Mettre en place une gouvernance responsable',
        nbQuestions: 2,
        imagePath: 'gouvernance.svg',
        alt: 'logo-gouvernance',
        style: {
          backgroundColor: '#3165cc!important'
        },
        questions: [
            { id: 0, 
              nom: "La stratégie RSE de l'entreprise existe-t-elle et est-elle intégrée à sa stratégie globale ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Pas du tout" },
                { id: '4', value: '8', text: "Des actions RSE sont mises en place mais une stratégie n'est pas formalisée" },
                { id: '5', value: '12', text: "Notre stratégie est formalisée et connue de tous les salariés" },
                { id: '6', value: '17', text: "La RSE est pleinement intégrée à la stratégie de l'entreprise, elle est connue des clients et fournisseurs et est portée par les dirigeants" }
              ] },
            { id: 1, 
              nom: "Avez-vous identifié les différents risques auxquels l'entreprise fait face et sont-ils suivis ?",  
              choisi: null,
              pertinence:null, 
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Pas du tout" },
                { id: '4', value: '8', text: "L'entreprise a identifié les risques économiques" },
                { id: '5', value: '12', text: "L'entreprise est consciente des risques sociaux (réputation, marque employeur, devoir de vigilance…) et environnementaux (raréfactions des ressources naturelles, énergies fossiles, changement climatique…) mais ils ne sont pas formalisés." },
                { id: '6', value: '17', text: "Les risques sont parfaitement connus et pilotés, un plan d'actions est mis en place" }
              ] }
          ]
    },
    {
        id: 1,
        nom: "Droits des personnes",
        titre: 'Respecter les droits des personnes',
        nbQuestions: 3,
        imagePath: 'human-rights.svg', 
        alt: 'logo-droits-des-personnes',
        style: {
          backgroundColor: '#cc5522!important'
        }, 
        questions: [
            { id: 2, 
              nom: "Des moyens sont-ils mis en oeuvre pour éviter toute forme de harcèlement ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non" },
                { id: '4', value: '8', text: "Les collaborateurs sont sensibilisés aux situations de harcèlement" },
                { id: '5', value: '12', text: "L'entreprise affiche des sanctions" },
                { id: '6', value: '17', text: "L'entreprise est très mature sur le sujet, a mis en place un système d'alerte et lutte activement contre toute forme de harcèlement" }
              ] },
            { id: 3, 
              nom: "Les profils de l'entreprise sont-ils diversifiés (genre, âge, handicap…) ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non" },
                { id: '4', value: '8', text: "L'entreprise s'engage à lutter contre les discriminations" },
                { id: '5', value: '12', text: "Les RH sont formés et ont intégré des processus en faveur de la diversité" },
                { id: '6', value: '17', text: "L'entreprise s'appuie sur des partenaires spécialisés sur la diversité pour recruter" }
              ] },
            { id: 4, 
              nom: "La protection des données de l'ensemble des parties prenantes est-elle assurée ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Pas du tout" },
                { id: '4', value: '8', text: "L'entreprise respecte la réglementation en matière de collecte et de protection des données " },
                { id: '5', value: '12', text: "De nombreuses actions sont engagées au-delà du règlementaire" },
                { id: '6', value: '17', text: "L'entreprise est avancée sur le sujet et la protection des données est essentielle" }
              ] }
          ]
    },
    {
        id: 2,
        nom: 'Relations et conditions de travail',
        titre: 'Développer des relations et conditions de travail responsables',
        nbQuestions: 6,
        imagePath: 'relation-travail.svg',
        alt: 'logo-relations-et-conditions-de-travail',
        style: {
          backgroundColor: '#5B5EA4!important'
        }, 
        questions: [
            { id: 5, 
              nom: "L'entreprise minimise-t-elle le nombre d'emplois précaires ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non" },
                { id: '4', value: '8', text: "Elle y travaille mais il y a encore beaucoup de CDD et d'intérimaires" },
                { id: '5', value: '12', text: "L'entreprise a fortement réduit le nombre d'emplois précaires" },
                { id: '6', value: '17', text: "Tous les emplois sont pérennes et l'entreprise s'engage en faveur de l'insertion" }
              ] },
            { id: 6, 
              nom: "La qualité de vie au travail est-elle assurée pour l'ensemble des salariés  (équilibre entre vie pro et vie perso, management participatif, conditions de travail…) ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, aucune action n'est mise en œuvre sur le sujet" },
                { id: '4', value: '8', text: "De premières actions sont mises en place pour améliorer le confort des locaux mais l'entreprise peut progresser." },
                { id: '5', value: '12', text: "De nombreuses actions sont engagées, notamment sur l'équilibre vie pro-vie perso, l'écoute des salariés et les premiers résultats sont visibles." },
                { id: '6', value: '17', text: "L'entreprise est très mature sur le sujet avec un management participatif, elle pilote ses actions et les résultats sont reconnus de tous." }
              ] },
            { id: 7, 
              nom: "Le télétravail est-il possible ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non" },
                { id: '4', value: '8', text: "Oui pour une partie des salariés" },
                { id: '5', value: '12', text: "Oui pour tous mais au maximum 1 jour par semaine" },
                { id: '6', value: '17', text: "Oui pour 100% des postes télétravaillables et ce plusieurs jours par semaine" }
              ] },
            { id: 8, 
              nom: "L'entreprise travaille-t-elle activement pour réduire les accidents du travail ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non il n'y a pas d'actions en cours" },
                { id: '4', value: '8', text: "De premières actions sont mises en œuvre mais l'entreprise peut progresser" },
                { id: '5', value: '12', text: "De nombreuses actions sont engagées et les accidents du travail sont en baisse" },
                { id: '6', value: '17', text: "L'entreprise est très mature sur le sujet, elle pilote ses actions et a de très bons résultats" }
              ] },
            { id: 9, 
              nom: "L'ensemble des salariés sont-ils formés ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non c'est très rare" },
                { id: '4', value: '8', text: "Une fois tous les 2 ans" },
                { id: '5', value: '12', text: "Une fois par an" },
                { id: '6', value: '17', text: "La formation s'inscrit dans le cadre d'une gestion des emplois et des compétences et anticipe les besoins futurs" }
              ] },
            { id: 10, 
              nom: "Le système de rémunération est-il équitable et transparent ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non c'est opaque" },
                { id: '4', value: '8', text: "Le système est informel" },
                { id: '5', value: '12', text: "La grille de salaire existe, elle est équitable (égalité femme-homme…) et est partagée." },
                { id: '6', value: '17', text: "La grille de salaire existe, le processus de décision est transparent et l'écart salarial est minimisé" }
              ] }

        ]
    },
    {
        id: 3,
        nom: 'Environnement',
        titre: "Préserver l'environnement",
        nbQuestions: 5,
        imagePath: 'environnement.svg', 
        alt: 'logo-environnement',
        style: {
          backgroundColor: '#51806a!important'
        }, 
        questions: [
            { id: 11, 
              nom: "L'entreprise intègre-t-elle les impacts environnementaux dans ses produits et services (éco-conception) ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Pas du tout" },
                { id: '4', value: '8', text: "Quelques actions visant à réduire l'impact sont mises en place sur une petite partie des produits et services" },
                { id: '5', value: '12', text: "Plusieurs actions sont mises en place et il existe quelques produits et services éco-conçus" },
                { id: '6', value: '17', text: "Un processus d'éco-conception est mis en place pour tous les produits et services" }
              ] },
            { id: 12, 
              nom: "L'entreprise réduit-elle sa production de déchets et augmente-t-elle leur taux de valorisation matière et organique ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "L'entreprise ne trie pas et ne réduit pas ses déchets." },
                { id: '4', value: '8', text: "L'entreprise trie et recycle une partie de ses déchets." },
                { id: '5', value: '12', text: "L'entreprise pilote et réduit sa production de déchets et recycle lorsque c'est possible." },
                { id: '6', value: '17', text: "L'entreprise a une politique zéro déchet non valorisé et contribue à l'économie circulaire." }
              ] },
            { id: 13, 
              nom: "L'entreprise cherche-t-elle à diminuer ses émissions de gaz à effet de serre ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, elles augmentent" },
                { id: '4', value: '8', text: "L'entreprise a fait un bilan d'émissions de gaz à effet de serre mais ses émissions ne diminuent pas" },
                { id: '5', value: '12', text: "Oui et l'entreprise a un plan d'action pour faire baisser ses émissions" },
                { id: '6', value: '17', text: "L'entreprise est alignée sur les Accords de Paris et contribue à la neutralité carbone par sa trajectoire" }
              ] },
            { id: 14, 
              nom: "L'entreprise réduit-elle son impact sur la biodiversité ? ",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non rien n'est fait sur le sujet." },
                { id: '4', value: '8', text: "L'entreprise commence à prendre en compte le sujet." },
                { id: '5', value: '12', text: "L'entreprise mène quelques actions ponctuelles et développe des partenariats." },
                { id: '6', value: '17', text: "L'entreprise lutte contre l'artificialisation des sols et recrée des espaces naturels. " }
              ] },
            { id: 15, 
              nom: "L'entreprise réduit-elle l'impact du numérique ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, il n'y a pas d'actions en place." },
                { id: '4', value: '8', text: "L'entreprise sensibilise ses salariés au sujet." },
                { id: '5', value: '12', text: "L'entreprise allonge la durée de vie de ses équipements informatiques." },
                { id: '6', value: '17', text: "L'entreprise a une démarche avancée sur le sujet (durée de vie et recyclage du matériel, sites web éco-conçus…).  " }
              ] }
        ]
    },
    {
        id: 4,
        nom: "Relations d'affaires",
        titre: "Développer l'éthique dans les relations d'affaires",
        nbQuestions: 3,
        imagePath: 'relations-fournisseurs.svg',
        alt: 'logo-relations-affaires',
        style: {
          backgroundColor: '#3e1f47!important'
        },  
        questions: [
            { id: 16, 
              nom: "L'entreprise respecte-t-elle les délais de paiement ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non elle les dépasse régulièrement" },
                { id: '4', value: '8', text: "Les délais réglementaires sont respectés" },
                { id: '5', value: '12', text: "Des actions sont engagées pour aller au-delà de la réglementation" },
                { id: '6', value: '17', text: "L'entreprise est exemplaire et réduit le plus possible les délais de paiement" }
              ] },
            { id: 17, 
              nom: "L'entreprise lutte-t-elle contre toute forme de corruption ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, il n'y a pas d'actions en place" },
                { id: '4', value: '8', text: "De premières actions sont mises en œuvre mais l'entreprise peut progresser" },
                { id: '5', value: '12', text: "De nombreuses actions sont engagées et les premiers résultats sont visibles" },
                { id: '6', value: '17', text: "L'entreprise est très mature sur le sujet, elle pilote ses actions et leur efficacité" }
              ] },
            { id: 18, 
              nom: "Les critères RSE sont-ils pris en compte dans le choix des fournisseurs ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non" },
                { id: '4', value: '8', text: "Oui mais de manière informelle" },
                { id: '5', value: '12', text: "Les critères RSE sont pris en compte mais influencent peu la prise de décision" },
                { id: '6', value: '17', text: "Les critères RSE sont pris en compte dans l'ensemble des consultations et ont une influence importante" }
              ] }
        ]
    },
    {
        id: 5,
        nom: 'Intérêts des consommateurs',
        titre: 'Respecter les intérêts des consommateurs',
        nbQuestions: 3,
        imagePath: 'consommation.svg',
        alt: 'logo-interets-consommateurs',
        style: {
          backgroundColor: '#cc4647!important'
        }, 
        questions: [
            { id: 19, 
              nom: "Les contrats, les engagements vis-à-vis des clients et les informations sur les produits et services sont-elles transparentes ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non et l'entreprise doit s'améliorer sur le sujet" },
                { id: '4', value: '8', text: "De premières actions sont mises en œuvre mais l'entreprise peut progresser" },
                { id: '5', value: '12', text: "De nombreuses actions sont engagées et les premiers résultats sont visibles" },
                { id: '6', value: '17', text: "L'entreprise est reconnue pour la transparence de ses pratiques commerciales et sur ses produits et services." }
              ] },
              { id: 20, 
              nom: "L'entreprise accompagne-t-elle ses clients vers des modes de consommation plus responsables ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non, il n'y a pas d'actions en place." },
                { id: '4', value: '8', text: "L'entreprise réalise ponctuellement des communications sur la consommation responsable." },
                { id: '5', value: '12', text: "L'entreprise communique sur la valeur ajoutée de ses produits sur les enjeux sociaux et environnementaux." },
                { id: '6', value: '17', text: "L'entreprise promeut de nouveaux modes de consommation (location, économie de fonctionnalité, limitation des soldes…) et incite ses clients à moins consommer." }
              ] },
              { id: 21, 
              nom: "L'entreprise mesure-t-elle la satisfaction de ses clients ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non et l'entreprise doit s'améliorer sur le sujet" },
                { id: '4', value: '8', text: "Oui mais de manière ponctuelle" },
                { id: '5', value: '12', text: "Oui de manière systématique" },
                { id: '6', value: '17', text: "Oui et un plan d'actions est mis en place pour améliorer l'offre" }
              ] }
        ]
    },
    {
        id: 6, 
        nom: 'Développement local',
        titre: "Viser le développement local et l'intérêt général",
        nbQuestions: 2,
        alt: 'logo-developpement-local',
        imagePath: 'societal.svg', 
        style: {
          backgroundColor: '#197278!important'
        }, 
        questions: [
            { id: 22, 
              nom: "L'entreprise œuvre-t-elle pour le développement local ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non et l'entreprise doit s'améliorer sur le sujet" },
                { id: '4', value: '8', text: "Oui en recrutant principalement en local" },
                { id: '5', value: '12', text: "L'entreprise contribue au territoire à travers le recrutement et les achats" },
                { id: '6', value: '17', text: "L'entreprise est très engagée sur le sujet et mesure ses impacts sur le territoire" }
              ] },
            { id: 23, 
              nom: "L'entreprise contribue-t-elle à des initiatives d'intérêt général ?",  
              choisi: null,
              pertinence:null,
              commentaire:null,
              reponses: [
                { id: '3', value: '3', text: "Non" },
                { id: '4', value: '8', text: "Oui elle fait des dons ponctuels à des associations" },
                { id: '5', value: '12', text: "L'entreprise a un engagement pérenne auprès d'associations de son territoire" },
                { id: '6', value: '17', text: "L'entreprise est engagée sur le long terme auprès d'associations et met en place du mécénat de compétences pour ses salariés" }
              ] }
        ]
    }]
export default quest;
